<template>
  <div class="wrap" id="wrap" v-loading="loading">
    <cardTitleCom cardTitle="查询" id="search-card">
      <template slot="cardContent">
        <div class="navSearch">
          <!-- 高级搜索按钮 -->
          <seniorSearch
            v-model="queryParams"
            :getList="getList"
            :dropDownList="dropDownList"
            :isAdvancedSearch="false"
            :isBillDate="false"
            :isSearchInput="true"
            isSearchInputTitle="输入方案编号、名称"
          />
        </div>
      </template>
    </cardTitleCom>
    <cardTitleCom cardTitle="方案信息" class="optionInfo">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton
            :getList="getList"
            :isRecycleBtn="true"
            :isDeleteBtn="false"
            :isEnableBtn="true"
            :isCopyBtn="true"
            :multiple="multiple"
            @handleAdd="handleAdd"
            @handleDelete="handleDelete"
            @handleEnable="handleEnable"
            @handleRedoDelete="dialogOptions.show = true"
            @handleCopy="handleCopy"
          />
          <el-table
            v-loading="loadingTable"
            ref="multipleTable"
            @row-click="handleRowClick"
            tooltip-effect="dark"
            :data="tableData"
            @selection-change="handleSelectionChange"
            border
            :max-height="tableHeight"
            :height="tableHeight"
          >
            <el-table-column type="selection" width="50" align="center" />
            <el-table-column
              label="序号"
              align="center"
              prop="userId"
              width="80"
              type="index"
            />
            <el-table-column
              show-overflow-tooltip
              label="方案编号"
              align="center"
              prop="payChannelNo"
            >
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="handleDetail(scope.row)"
                  target="_blank"
                >
                  {{ scope.row.payChannelNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column
              label="方案名称"
              align="center"
              prop="payChannelName"
            />
            <el-table-column
              show-overflow-tooltip
              label="支付通道类型"
              align="center"
              prop="payChannelType"
            >
              <template slot-scope="scope">
                <dict-tag
                  :options="dict.type.shop_pay_type"
                  :value="scope.row.payChannelType"
                />
              </template>
            </el-table-column>
            <el-table-column
              label="状态"
              align="center"
              prop="isStop"
              width="100"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.isStop == 0 ? '启用' : '禁用' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="修改人"
              align="center"
              prop="updateBy"
              width="120"
            />
            <el-table-column
              label="修改时间"
              align="center"
              prop="updateTime"
              width="155"
            />
            <el-table-column label="创建人" align="center" prop="createBy" />
            <el-table-column
              label="创建时间"
              align="center"
              prop="createTime"
              width="155"
            />
          </el-table>
          <bottomPage
            v-model="queryParams"
            :getList="getList"
            :idsCom="ids"
            :totalCom="total"
            @selectAllCom="selectAll"
          />
        </div>
      </template>
    </cardTitleCom>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  payChannelListAPI,
  payChannelDeleteAPI,
  payChannelIsStopAPI,
  payChannelCopyAPI,
  restorePayChannel
} from '@/api/shop/base/payChannel' //支付通道
import operatingButton from '@/views/components/operatingButton' //操作按钮
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'payChannel',
  dicts: ['shop_pay_type'],
  components: {
    bottomPage,
    cardTitleCom,
    seniorSearch,
    operatingButton,
    Dialog2
  },
  data () {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: payChannelListAPI,
          restoreListApi: restorePayChannel,
          id: 'payChannelId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'payChannelNos', label: '方案编号' },
                { filter: 'payChannelNames', label: '方案名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'payChannelNo',
              label: '方案编号',
              minWidth: 180
            },
            {
              prop: 'payChannelName',
              label: '方案名称',
              minWidth: 180
            },
            {
              prop: 'payChannelTypeName',
              label: '支付通道类型',
              minWidth: 160
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 120
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'payChannelNos', name: '方案编号' },
        { parameter: 'payChannelNames', name: '方案名称' }
      ],
      loading: false,
      loadingTable: false,
      multiple: true, // 非单个禁用
      showHigh: false, //是否显示高级搜索
      billStatusStopId: [], //选中数组的禁用的状态
      billStatusIsStopId: [], //选中数组的启用的状态
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },

  async created () {
    //初始化表格数据
    await this.getList()
  },
  computed: {
    tableHeight () {
      return this.vivwH - this.paginationH - this.searchH - 140
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //还原刷新页面
    handleEvent () {
      this.loading = true
      this.getList()
      this.loading = false
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //删除支付通道
    async handleDelete () {
      this.$confirm('是否确认删除所选数据项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          //表格遮罩
          this.loadingTable = true
          await payChannelDeleteAPI(this.ids)
          await this.getList()
          this.$message({
            message: `删除成功`,
            type: 'success'
          })
          //表格遮罩
          this.loadingTable = false
        })
        .catch(() => {})
    },
    /** 查询表格列表 */
    async getList () {
      this.loadingTable = true
      const { rows, total } = await payChannelListAPI(this.queryParams)
      this.tableData = rows
      this.total = total
      this.loadingTable = false
    },
    //复制
    async handleCopy () {
      this.loadingTable = true
      await payChannelCopyAPI(this.ids)
      await this.getList()
      this.$message({
        message: `复制成功`,
        type: 'success'
      })
      this.loadingTable = false
    },
    //启用停用
    async handleEnable (value) {
      if (value) {
        if (this.billStatusIsStopId.length === 0) {
          this.$message.error('只能禁用已启用的状态')
          return
        }
        const obj = {
          payChannelIds: this.billStatusIsStopId,
          isStop: 1
        }
        await payChannelIsStopAPI(obj)
        this.$message({
          message: `禁用成功`,
          type: 'success'
        })
      } else {
        if (this.billStatusStopId.length === 0) {
          this.$message.error('只能启用已禁用的状态')
          return
        }
        const obj = {
          payChannelIds: this.billStatusStopId,
          isStop: 0
        }
        await payChannelIsStopAPI(obj)
        this.$message({
          message: `启用成功`,
          type: 'success'
        })
      }
      this.getList()
    },
    //新增单据事件
    handleAdd () {
      this.$router.push({
        name: 'payChannelDetail'
      })
    },
    //跳转详情
    handleDetail (row) {
      const payChannelId = row.payChannelId
      this.$router.push({
        name: 'payChannelDetail',
        query: {
          payChannelId: payChannelId,
          type: 'Update'
        }
      })
    },
    //点击行选中
    handleRowClick (row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    // 多选框选中数据
    handleSelectionChange (selection) {
      this.ids = selection.map(item => item.payChannelId) //单据id
      //筛选出来禁用的单据状态
      this.billStatusStopId = selection
        .map(item => {
          if (item.isStop || item.isStop == 1) {
            return item.payChannelId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      //筛选出来启用的单据状态
      this.billStatusIsStopId = selection
        .map(item => {
          if (!item.isStop || item.isStop == 0) {
            return item.payChannelId
          }
        })
        .filter(ite => typeof ite !== 'undefined')
      this.multiple = !selection.length
    },
    //点击全选所有数据
    async selectAll (value) {
      if (value) {
        this.loadingTable = true
        const res = await payChannelListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //是否显示高级搜索
    getIsShowHigh (value) {
      this.showHigh = value
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .optionInfo {
    height: calc(100% - 94px);
    //源单信息表格
    .tableContent {
      padding: 6px 10px;
    }
  }
}
</style>
