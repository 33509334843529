<template>
  <div class="wrap">
    <!-- 顶部按钮 -->
    <div class="btnTOP">
      <el-button type="primary" size="mini" @click="submitForm"
        >保存
      </el-button>
      <el-button @click="getQuit" size="mini">退出</el-button>
    </div>
    <cardTitleCom cardTitle="目标业绩" v-loading="loading">
      <template slot="cardContent">
        <div class="tableContent">
          <div style="height: 50px; line-height: 40px">
            <span class="fontS14 marR10">选择年份</span>
            <el-select
              class="years marR15"
              v-model="saleTargetForm.yearMonth"
              @change="getChangeSelect"
              placeholder="年份"
              filterable
            >
              <el-option
                v-for="item in timeList"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <span class="fontS14 marR10">选择门店</span>
            <SelectRemote
              v-model="saleTargetForm.shopId"
              style="width: 250px"
              @selectChange="getChangeSelect"
              :option="
                $select({
                  key: 'listShop'
                }).option
              "
            />
          </div>
          <el-table
            :data="details"
            max-height="780"
            border
            show-summary
            style="width: 100%"
          >
            <el-table-column
              label="月份"
              align="center"
              prop="month"
              width="80"
            />
            <el-table-column
              label="全店目标营业额（元）"
              align="right"
              prop="grossTurnover"
            />

            <el-table-column
              label="门店目标销售额（元）"
              align="left"
              prop="monthSale"
              width="350"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  @input="onkeyupUnitQty(scope.row)"
                  v-model="scope.row.monthSale"
                ></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="门店目标充值额（元）"
              align="left"
              prop="monthFill"
              width="350"
            >
              <template slot-scope="scope">
                <el-input
                  type="number"
                  @input="onkeyupUnitQty(scope.row)"
                  v-model="scope.row.monthFill"
                ></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </cardTitleCom>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  saleTargetSaveOrUpdateAPI,
  saleTargetDetailAPI
} from '@/api/shop/base/saleTarget'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import { fcount } from '@/utils'
export default {
  name: 'saleTargetDetail',
  components: { cardTitleCom, SelectRemote },
  data () {
    return {
      // 遮罩层
      loading: false,
      //目标年份
      timeList: [2023, 2024, 2025],
      //表格
      details: [
        { month: '1月' },
        { month: '2月' },
        { month: '3月' },
        { month: '4月' },
        { month: '5月' },
        { month: '6月' },
        { month: '7月' },
        { month: '8月' },
        { month: '9月' },
        { month: '10月' },
        { month: '11月' },
        { month: '12月' }
      ],
      //目标业绩表单
      saleTargetForm: {}
    }
  },
  created () {
    //初始化
    this.getInitializeData()
    if (this.$route.query.type === 'Update') {
      //业绩详情
      this.getSaleTargetDetail(this.$route.query.kpiId)
    }
  },
  methods: {
    //门店切换
    async getChangeSelect (val) {
      if (!this.saleTargetForm.shopId) {
        this.$message.error('门店不能为空')
        return
      }
      if (!this.saleTargetForm.yearMonth) {
        this.$message.error('年份不能为空')
        return
      }
      this.loading = true
      //详情
      await this.getSaleTargetDetail({
        shopId: this.saleTargetForm.shopId,
        yearMonth: this.saleTargetForm.yearMonth
      })
      this.$message({
        message: '切换成功',
        type: 'success'
      })
      this.loading = false
    },
    //业绩详情
    async getSaleTargetDetail (value) {
      this.loading = true
      // 表单重置
      this.reset()
      const res = await saleTargetDetailAPI(value)
      if (res.data && res.data.yearMonth) {
        this.saleTargetForm = res.data
        //计算详情
        this.getCountDetail()
      }
      this.loading = false
    },
    //计算详情
    getCountDetail () {
      this.details.forEach(item => {
        // 根据月份进行计算
        switch (item.month) {
          case '1月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill01) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale01) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill01,
                  this.saleTargetForm.monthSale01
                ],
                '+'
              )
            ) //全店目标
            break
          case '2月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill02) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale02) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill02,
                  this.saleTargetForm.monthSale02
                ],
                '+'
              )
            ) //全店目标
            break
          case '3月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill03) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale03) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill03,
                  this.saleTargetForm.monthSale03
                ],
                '+'
              )
            ) //全店目标
            break
          case '4月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill04) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale04) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill04,
                  this.saleTargetForm.monthSale04
                ],
                '+'
              )
            ) //全店目标
            break
          case '5月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill05) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale05) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill05,
                  this.saleTargetForm.monthSale05
                ],
                '+'
              )
            ) //全店目标
            break
          case '6月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill06) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale06) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill06,
                  this.saleTargetForm.monthSale06
                ],
                '+'
              )
            ) //全店目标
            break
          case '7月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill07) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale07) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill07,
                  this.saleTargetForm.monthSale07
                ],
                '+'
              )
            ) //全店目标
            break
          case '8月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill08) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale08) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill08,
                  this.saleTargetForm.monthSale08
                ],
                '+'
              )
            ) //全店目标
            break
          case '9月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill09) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale09) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill09,
                  this.saleTargetForm.monthSale09
                ],
                '+'
              )
            ) //全店目标
            break
          case '10月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill10) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale10) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill10,
                  this.saleTargetForm.monthSale10
                ],
                '+'
              )
            ) //全店目标
            break
          case '11月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill11) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale11) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill11,
                  this.saleTargetForm.monthSale11
                ],
                '+'
              )
            ) //全店目标
            break
          case '12月':
            this.$set(item, 'monthFill', this.saleTargetForm.monthFill12) //充值目标
            this.$set(item, 'monthSale', this.saleTargetForm.monthSale12) //销售目标
            this.$set(
              item,
              'grossTurnover',
              fcount(
                [
                  this.saleTargetForm.monthFill12,
                  this.saleTargetForm.monthSale12
                ],
                '+'
              )
            ) //全店目标
            break
          default:
            console.error('未知的计算符号') // 报错
        }
      })
    },
    // 表单重置
    reset () {
      this.details = [
        { month: '1月' },
        { month: '2月' },
        { month: '3月' },
        { month: '4月' },
        { month: '5月' },
        { month: '6月' },
        { month: '7月' },
        { month: '8月' },
        { month: '9月' },
        { month: '10月' },
        { month: '11月' },
        { month: '12月' }
      ]
    },
    //目标业绩键盘弹起
    onkeyupUnitQty (row) {
      this.$set(
        row,
        'grossTurnover',
        fcount([row.monthSale, row.monthFill], '+')
      )
    },
    //初始化页面数据
    async getInitializeData () {
      let currentDate = new Date() // 获取当前日期
      let currentYear = currentDate.getFullYear() // 获取当前年份
      let thisYear = currentYear // 今年
      let nextYear = currentYear + 1 // 明年
      let afterNextYear = currentYear + 2 // 后年
      this.timeList = [thisYear, nextYear, afterNextYear]
      this.saleTargetForm.yearMonth = this.timeList[0]
    },
    //退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy()
      this.$router.push('/shop/base/saleTarget')
    },
    /** 提交按钮 */
    async submitForm () {
      if (!this.saleTargetForm.shopId || !this.saleTargetForm.yearMonth) {
        this.$message({
          message: '年份或门店不能为空',
          type: 'warning'
        })
        return
      }
      this.details.forEach(item => {
        // 根据月份进行计算
        switch (item.month) {
          case '1月':
            this.$set(this.saleTargetForm, 'monthFill01', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale01', item.monthSale) //销售目标
            break
          case '2月':
            this.$set(this.saleTargetForm, 'monthFill02', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale02', item.monthSale) //销售目标
            break
          case '3月':
            this.$set(this.saleTargetForm, 'monthFill03', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale03', item.monthSale) //销售目标
            break
          case '4月':
            this.$set(this.saleTargetForm, 'monthFill04', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale04', item.monthSale) //销售目标
            break
          case '5月':
            this.$set(this.saleTargetForm, 'monthFill05', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale05', item.monthSale) //销售目标
            break
          case '6月':
            this.$set(this.saleTargetForm, 'monthFill06', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale06', item.monthSale) //销售目标
            break
          case '7月':
            this.$set(this.saleTargetForm, 'monthFill07', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale07', item.monthSale) //销售目标
            break
          case '8月':
            this.$set(this.saleTargetForm, 'monthFill08', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale08', item.monthSale) //销售目标
            break
          case '9月':
            this.$set(this.saleTargetForm, 'monthFill09', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale09', item.monthSale) //销售目标
            break
          case '10月':
            this.$set(this.saleTargetForm, 'monthFill10', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale10', item.monthSale) //销售目标
            break
          case '11月':
            this.$set(this.saleTargetForm, 'monthFill11', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale11', item.monthSale) //销售目标
            break
          case '12月':
            this.$set(this.saleTargetForm, 'monthFill12', item.monthFill) //充值目标
            this.$set(this.saleTargetForm, 'monthSale12', item.monthSale) //销售目标
            break
          default:
            console.error('未知的计算符号') // 报错
        }
      })
      await saleTargetSaveOrUpdateAPI(this.saleTargetForm)
      this.$message({
        message: '编辑成功',
        type: 'success'
      })
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$router.push('/shop/base/saleTarget')
      this.$destroy()
    },
    //自动计算总数
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[index] += ' 元'
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  padding: 6px 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  //源单信息表格
  .tableContent {
    padding: 6px 10px;
    //选择年份
    .years {
      width: 100px;
    }
    //输入框原生属性
    /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
    ::v-deep .el-input__inner {
      line-height: 1px !important;
    }

    /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
    ::v-deep input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>
