<template>
  <paymode />
</template>

<script>
import paymode from '@/views/shop/base/paymode/components'
export default {
  name: 'paymode',
  components: { paymode }
}
</script>
