<template>
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="机器管理" @allNodeIds="allNodeIds" :isChildLevelIds="true" :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="shopTreeOptions" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
              :isAdvancedSearch="false" :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入机器编码、名称" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="单据信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
              :hasPermiAddBtn="['shop:machine:list:add']" :hasPermiRecycleBtn="['shop:machine:list:remove']"
              @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true">
              <template #specialDiyFinalBtn>
                <el-button v-hasPermi="['shop:machine:list:reset']" type="primary" icon="el-icon-connection" size="mini"
                  @click="untieOperation">重置
                </el-button>
              </template>
            </operatingButton>
            <div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange" border v-loading="loadingTable" @row-click="handleRowClick"
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column label="序号" align="center" type="index" width="80" />
                <el-table-column label="收银机编号" align="center" prop="machineNo" show-overflow-tooltip min-width="180">
                  <template slot-scope="scope">
                    <el-link type="primary" @click="handleDetail(scope.row)" target="_blank" ref="elLink">
                      {{ scope.row.machineNo }}
                    </el-link>
                    <!-- v-hasPermi="['shop:machine:list:edit']" -->
                    <!-- <span v-if="!editPermission">
                      {{ scope.row.machineNo }}</span
                    > -->
                    <!-- <span>{{ scope.row.machineNo }}</span> -->
                  </template>
                </el-table-column>
                <el-table-column label="收银机名称" align="center" prop="machineName" show-overflow-tooltip
                  min-width="180" />
                <el-table-column align="center" key="shopNo" prop="shopNo" label="门店编号" min-width="180"
                  show-overflow-tooltip />
                <el-table-column label="门店名称" align="center" key="shopName" prop="shopName" show-overflow-tooltip
                  min-width="180" />
                <el-table-column label="是否主收银机" align="center" prop="isMain" min-width="100">
                  <template slot-scope="scope">
                    {{ scope.row.isMain ? '主收银机' : '副收银机' }}
                  </template>
                </el-table-column>
                <el-table-column label="助记码" align="center" key="zjm" prop="zjm" show-overflow-tooltip
                  min-width="120" />
                <el-table-column label="计算机名称" align="center" prop="deviceName" show-overflow-tooltip min-width="165" />
                <el-table-column label="工作状态" align="center" prop="status" show-overflow-tooltip>
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.machine_working_state" :value="scope.row.status" />
                  </template>
                </el-table-column>

                <el-table-column label="应用终端类型" align="center" prop="applicationTerminalType" show-overflow-tooltip
                  min-width="120" />
                <el-table-column label="下载终端" align="center" prop="downloadTerminal" show-overflow-tooltip />
                <el-table-column label="锁定人" align="center" prop="lockCashierName" min-width="120" />
                <el-table-column label="开始使用时间" align="center" prop="startUseTime" show-overflow-tooltip
                  min-width="155" />
                <el-table-column label="最近使用时间" align="center" prop="lastUseTime" show-overflow-tooltip
                  min-width="155" />
                <el-table-column label="备注" align="center" prop="remark" :show-overflow-tooltip="true"
                  min-width="180" />
                <el-table-column label="重置人" align="center" prop="resetBy" min-width="120" />
                <el-table-column label="重置时间" align="center" prop="resetTime" min-width="155" />
                <el-table-column label="修改人" align="center" key="updateBy" prop="updateBy" :show-overflow-tooltip="true"
                  min-width="130" />
                <el-table-column label="修改时间" align="center" prop="updateTime" show-overflow-tooltip min-width="155">
                </el-table-column>
                <el-table-column label="创建人" align="center" key="createBy" prop="createBy" :show-overflow-tooltip="true"
                  min-width="130" />
                <el-table-column label="创建时间" align="center" prop="createTime" show-overflow-tooltip min-width="155">
                </el-table-column>
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 新增或修改 -->
    <c-dialog :title="title" :width="500" :showDialog.sync="open">
      <template #content>
        <el-form v-loading="DetailLoading" ref="form" :model="machineForm" :rules="rules" label-width="100px">
          <el-row>
            <el-col :span="22">
              <el-form-item label="所属门店" prop="shopId">
                <treeselect v-model="machineForm.shopId" :options="shopTreeOptions" :show-count="true"
                  placeholder="请选择门店名称" :normalizer="normalizer" @input="inputSelect" :disable-branch-nodes="true"
                  :multiple="false" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="收银机编号" prop="machineNo">
                <el-input disabled class="addinput" v-model="machineForm.machineNo" placeholder="请输入收银机编号"
                  maxlength="20" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="收银机名称" prop="machineName">
                <el-input class="addinput" v-model="machineForm.machineName" placeholder="请输入收银机名称" maxlength="40" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="22">
              <el-form-item label="是否主收银机" prop="isMain">
                <el-radio-group v-model="machineForm.isMain">
                  <el-radio :label="true">是</el-radio>
                  <el-radio :label="false">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row>
            <el-col :span="22">
              <el-form-item label="计算机名称" prop="deviceName">
                <el-input
                  class="addinput"
                  v-model="machineForm.deviceName"
                  placeholder="请输入计算机名称"
                  maxlength="80"
                />
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="22">
              <el-form-item label="备注" prop="remark">
                <el-input class="addinput" type="textarea" placeholder="备注长度介于 1 和 80 字符之间" v-model="machineForm.remark"
                  :autosize="{ minRows: 2, maxRows: 4 }" maxlength="80">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" class="marL10" @click="closeDia">取消</el-button>
        <el-button size="mini" type="primary" @click="saveAndAdd" class="marL10"
          v-hasPermi="['shop:machine:list:add']">保存并新增</el-button>
        <el-button size="mini" type="primary" @click="submitForm" class="marL10"
          v-hasPermi="['shop:machine:list:add']">保存</el-button>
      </template>
    </c-dialog>
    <!-- 重置 -->
    <c-dialog title="请输入登录密码" :width="450" :showDialog.sync="resetUnbindOpen">
      <template #content>
        <el-form :model="resetUnbindForm" ref="permissionForm" label-width="90px" @submit.native.prevent>
          <el-form-item label="密码" prop="sourceCashierId">
            <el-input ref="inputRef" style="width: 250px" show-password placeholder="请输入登录密码" size="mini"
              v-model="resetUnbindForm.password" @keydown.enter.native='submitResetUnbindForm'></el-input>
          </el-form-item>
        </el-form>
        <div class="x-f">
          <span>*</span>
          <span class="fontS14" style="color: #ff4949">重置收银机，可能会导致数据丢失，请谨慎操作</span>
        </div>
      </template>

      <template #buttonList>
        <el-button size="mini" class="marL10" @click="resetUnbindOpen = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitResetUnbindForm" class="marL10">确 定</el-button>
      </template>
    </c-dialog>
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog2 from '@/components/Dialog' //回收站
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import { getToken } from '@/utils/auth'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  listMachine,
  addMachine,
  getMachineDetail,
  updateMachine,
  delMachine,
  resetMachine,
  getMachineBillNo,
  restoreMachine
} from '@/api/shop/base/machine.js'

import { getDeptTree } from '@/api/shop/base/shopInfo'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
export default {
  name: 'ShopBaseMachine',
  dicts: ['machine_working_state'],
  components: {
    Treeselect,
    CDialog,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    Dialog2
  },
  data() {
    return {
      //重置
      resetUnbindForm: {
        password: null
      },
      //重置弹窗
      resetUnbindOpen: false,
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listMachine,
          restoreListApi: restoreMachine,
          id: 'machineId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'machineNos', label: '收银机编号' },
                { filter: 'machineNames', label: '收银机名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'machineNo',
              label: '收银机编号',
              minWidth: 180
            },
            {
              prop: 'machineName',
              label: '收银机名称',
              minWidth: 180
            },
            {
              prop: 'shopNo',
              label: '门店编码',
              minWidth: 180
            },
            {
              prop: 'shopName',
              label: '门店名称',
              minWidth: 180
            },
            {
              prop: 'zjm',
              label: '助记码',
              minWidth: 100
            },
            {
              prop: 'deviceName',
              label: '计算机名称',
              minWidth: 120
            },
            {
              prop: 'applicationTerminalType',
              label: '应用终端类型',
              minWidth: 120
            },
            {
              prop: 'downloadTerminal',
              label: '下载终端',
              minWidth: 120
            },
            {
              prop: 'startUseTime',
              label: '开始使用时间',
              minWidth: 130
            },
            {
              prop: 'lastUseTime',
              label: '最近使用时间',
              minWidth: 120
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 160
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 130
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 130
            },
            {
              prop: 'createBy',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'machineNos', name: '收银机编号' },
        { parameter: 'machineNames', name: '收银机名称' }
      ],
      loadingTable: false, //表格加载
      DetailLoading: false, //弹框加载
      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      //选中数组的名称
      name: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 部门机构树选项
      shopTreeOptions: [],
      // 分类表格数据
      tableData: [],
      // 收银员弹出层标题
      machinetitle: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      machineForm: {
        shopId: null,
        machineName: null,
        machineNo: null,
        deviceName: null,
        remark: null,
        isMain: false
      },
      title: '新增收银机',
      //新增表单校验
      rules: {
        shopId: [
          {
            required: true,
            message: '所属门店不能为空',
            trigger: 'input'
          }
        ],
        machineName: [
          {
            required: true,
            message: '收银机名称不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[\u4E00-\u9FA5A-Za-z0-9]{1,40}$/,
            message: '请输入1 ~ 40个数字或英文的字符'
          }
        ],
        machineNo: [
          {
            required: true,
            message: '收银机编号不能为空',
            trigger: ['blur', 'change']
          },
          {
            pattern: /^[A-Za-z0-9]{1,20}$/,
            message: '请输入1 ~ 20个数值或英文的字符'
          }
        ]
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 分类导入参数
      upload: {
        // 是否显示弹出层（分类导入）
        open: false,
        // 收银员弹出层标题（分类导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的分类数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/system/user/importData'
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  watch: {
    open(val) {
      if (!val) {
        this.$refs.form.resetFields()
      }
    }
  },
  created() {
    this.loading = true
    this.getList()
    this.getTreeselect()
    this.loading = false
  },
  computed: {
    // editPermission () {
    //   // 这里调用你的全局自定义指令v-hasPermi，并根据返回值设置hasPermission的值
    //   // 例如：
    //   console.log('elLink2', this.$refs.elLink2)
    //   console.log('elLink', this.$refs.elLink)
    //   return this.$refs.elLink
    // },
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //确认重置收银
    async submitResetUnbindForm() {
      if (!this.resetUnbindForm.password)
        return this.$message.warning('请输入密码')
      this.$modal
        .confirm('是否确认重置此收银机？')
        .then(async () => {
          await resetMachine({
            password: this.resetUnbindForm.password,
            machineIds: this.ids
          })
          return
        })
        .then(() => {
          this.resetUnbindOpen = false
          this.getList()
          this.$modal.msgSuccess('重置成功')
        })
    },
    //还原刷新页面
    handleEvent() {
      this.loading = true
      this.getTreeselect()
      this.getList()
      this.loading = false
    },
    // 节点单击事件(左边的机构树)
    allNodeIds(value) {
      this.queryParams.deptIds = value
      this.getList()
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap')?.clientHeight
      this.searchH = document.getElementById('search-card')?.clientHeight
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listMachine({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //  修改\点击编号
    async handleDetail(row) {
      this.title = '修改收银机'
      this.open = true
      this.DetailLoading = true
      this.reset()
      this.getTreeselect()
      //获取品牌详情
      getMachineDetail(row.machineId).then(response => {
        this.machineForm = response.data
        this.DetailLoading = false
      })
    },
    // 下拉框校验方法
    inputSelect() {
      this.$refs.form.validateField('shopId')
    },
    //获取机构
    /** 查询分类下拉树结构 */
    getTreeselect() {
      getDeptTree().then(response => {
        this.shopTreeOptions = response.data
      })
    },
    //下拉树结构数据转换
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children
      }
      return {
        id: node.id,
        label: node.label,
        children: node.children,
        isDisabled: node.number == '0000' ? true : false
      }
    },

    /** 查询分类列表 */
    getList() {
      this.loadingTable = true
      listMachine(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },
    // 表单重置
    reset() {
      this.machineForm = {
        shopId: null,
        machineName: null,
        machineNo: null,
        deviceName: null,
        remark: null,
        isMain: false
      }
      this.resetForm('form')
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.machineId)
      this.name = selection.map(item => item.machineName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    async handleAdd() {
      this.title = '新增收银机'
      this.open = true
      this.DetailLoading = true
      this.reset()
      // this.getTreeselect()
      const res = await getMachineBillNo()
      this.machineForm.machineNo = res.data
      this.DetailLoading = false
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.machineForm.machineId != undefined) {
            updateMachine(this.machineForm).then(response => {
              this.$modal.msgSuccess('修改收银机成功')
              this.open = false
              this.getList()
              this.getTreeselect()
            })
          } else {
            addMachine(this.machineForm).then(response => {
              this.$modal.msgSuccess('新增收银机成功')
              this.open = false
              this.getList()
              this.getTreeselect()
            })
          }
        }
      })
    },
    // 保存并新增
    saveAndAdd: function () {
      this.$refs['form'].validate(valid => {
        if (valid) {
          addMachine(this.machineForm).then(async response => {
            this.DetailLoading = true
            await this.reset()
            const res = await getMachineBillNo()
            this.machineForm.machineNo = res.data
            this.$modal.msgSuccess('保存新增成功')
            this.DetailLoading = false
          })
        }
      })
    },
    /** 删除按钮操作 */
    handleDelete() {
      this.$modal
        .confirm('是否确认删除分类名称为"' + this.name + '"的数据项？')
        .then(() => {
          return delMachine(this.ids)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除收银机成功')
        })
        .catch(() => { })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/shop/base/machine/export',
        {
          ...this.queryParams
        },
        `machine_${new Date().getTime()}.xlsx`
      )
    },
    // 解绑
    untieOperation() {
      if (this.ids.length !== 1)
        return this.$message.warning('请选择一台机器重置')
      this.resetUnbindForm.password = null
      this.resetUnbindOpen = true
      this.$nextTick(() => {
        this.$refs.inputRef.focus()
      })
    },
    // 关闭弹窗
    closeDia() {
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      margin-bottom: 6px;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}

.addinput {
  width: 250px;
}

//输入框的宽度

::v-deep .vue-treeselect__control {
  width: 250px;
}

//treeselect
::v-deep .vue-treeselect__menu-container {
  width: 250px;
}
</style>
