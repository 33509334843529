var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap" },
    [
      _c(
        "cardTitleCom",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { cardTitle: "门店目标业绩" },
        },
        [
          _c("template", { slot: "cardContent" }, [
            _c("div", { staticClass: "tableContent" }, [
              _c("div", { staticClass: "tableBtn" }, [
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "el-icon-plus",
                          size: "mini",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.push(
                              "/shop/base/saleTarget/saleTargetDetail"
                            )
                          },
                        },
                      },
                      [_vm._v("新增")]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "marR10",
                        attrs: { size: "mini", icon: "el-icon-refresh" },
                        on: {
                          click: function ($event) {
                            return _vm.test($event)
                          },
                        },
                      },
                      [_vm._v("刷新 ")]
                    ),
                  ],
                  1
                ),
              ]),
              _c("div", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.thisYearLoading,
                        expression: "thisYearLoading",
                      },
                    ],
                    staticClass: "targetPerformance",
                  },
                  [
                    _c("div", { staticClass: "targetTurnover padL10 padR10" }, [
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "fontS14 marR10" }, [
                            _vm._v(_vm._s(_vm.thisYear) + "年 目标营业额(元)"),
                          ]),
                          _c("span", { staticClass: "fontS14 marR10" }, [
                            _vm._v("选择门店"),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "inputWidthM marR15",
                              attrs: {
                                placeholder: "全部门店",
                                filterable: "",
                                clearable: "",
                                multiple: "",
                                "collapse-tags": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getChangeShop("thisYear")
                                },
                              },
                              model: {
                                value: _vm.thisYearShopArr,
                                callback: function ($$v) {
                                  _vm.thisYearShopArr = $$v
                                },
                                expression: "thisYearShopArr",
                              },
                            },
                            _vm._l(_vm.shopInfoData, function (item) {
                              return _c("el-option", {
                                key: item.shopId,
                                attrs: {
                                  label: item.shopName,
                                  value: item.shopId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "targetContent" }, [
                      _c("div", { staticClass: "targetSummary" }, [
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualTarget.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("全店年度目标"),
                                ]),
                                _vm.thisYearObj.totalAmount
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.totalAmount,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.thisYearObj.totalAmount
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualsales.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("门店年度销售额"),
                                ]),
                                _vm.thisYearObj.monthSaleTotal
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.thisYearObj.monthSaleTotal,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.thisYearObj.monthSaleTotal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualRecharge.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("门店年度充值额"),
                                ]),
                                _vm.thisYearObj.monthFillTotal
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.thisYearObj.monthFillTotal,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.thisYearObj.monthFillTotal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "targetData" }, [
                        _c("div", { staticClass: "targetLine" }, [
                          _c("div", { staticClass: "targetBox" }, [
                            _c("p", { staticClass: "fontS14 targetBoxTop" }, [
                              _vm._v("1月"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店销售"),
                                ]),
                                _vm.thisYearObj.monthSale01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店充值"),
                                ]),
                                _vm.thisYearObj.monthFill01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fontS14 lineWhole" },
                                  [_vm._v("全店")]
                                ),
                                _vm.thisYearObj.shopTarget01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("2月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("3月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("4月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale04
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm.thisYearObj.monthFill04
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "fontS14 omit",
                                                staticStyle: { width: "80px" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.thisYearObj.monthFill04
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget04
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("5月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("6月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "targetLine" }, [
                          _c("div", { staticClass: "targetBox" }, [
                            _c("p", { staticClass: "fontS14 targetBoxTop" }, [
                              _vm._v("7月"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店销售"),
                                ]),
                                _vm.thisYearObj.monthSale07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店充值"),
                                ]),
                                _vm.thisYearObj.monthFill07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fontS14 lineWhole" },
                                  [_vm._v("全店")]
                                ),
                                _vm.thisYearObj.shopTarget07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("8月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("9月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("10月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("11月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("12月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthSale12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthSale12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthSale12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.monthFill12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.monthFill12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.monthFill12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.thisYearObj.shopTarget12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.thisYearObj.shopTarget12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.thisYearObj.shopTarget12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.nextYearLoading,
                        expression: "nextYearLoading",
                      },
                    ],
                    staticClass: "targetPerformance",
                  },
                  [
                    _c("div", { staticClass: "targetTurnover padL10 padR10" }, [
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "fontS14 marR10" }, [
                            _vm._v(_vm._s(_vm.nextYear) + "年 目标营业额(元)"),
                          ]),
                          _c("span", { staticClass: "fontS14 marR10" }, [
                            _vm._v("选择门店"),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "inputWidthM marR15",
                              attrs: {
                                placeholder: "全部门店",
                                filterable: "",
                                clearable: "",
                                multiple: "",
                                "collapse-tags": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getChangeShop("nextYear")
                                },
                              },
                              model: {
                                value: _vm.nextYearShopArr,
                                callback: function ($$v) {
                                  _vm.nextYearShopArr = $$v
                                },
                                expression: "nextYearShopArr",
                              },
                            },
                            _vm._l(_vm.shopInfoData, function (item) {
                              return _c("el-option", {
                                key: item.shopId,
                                attrs: {
                                  label: item.shopName,
                                  value: item.shopId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "targetContent" }, [
                      _c("div", { staticClass: "targetSummary" }, [
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualTarget.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("全店年度目标"),
                                ]),
                                _vm.nextYearObj.totalAmount
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.totalAmount,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.nextYearObj.totalAmount
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualsales.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("门店年度销售额"),
                                ]),
                                _vm.nextYearObj.monthSaleTotal
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.nextYearObj.monthSaleTotal,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.nextYearObj.monthSaleTotal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualRecharge.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("门店年度充值额"),
                                ]),
                                _vm.nextYearObj.monthFillTotal
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.nextYearObj.monthFillTotal,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.nextYearObj.monthFillTotal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "targetData" }, [
                        _c("div", { staticClass: "targetLine" }, [
                          _c("div", { staticClass: "targetBox" }, [
                            _c("p", { staticClass: "fontS14 targetBoxTop" }, [
                              _vm._v("1月"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店销售"),
                                ]),
                                _vm.nextYearObj.monthSale01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店充值"),
                                ]),
                                _vm.nextYearObj.monthFill01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fontS14 lineWhole" },
                                  [_vm._v("全店")]
                                ),
                                _vm.nextYearObj.shopTarget01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("2月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("3月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("4月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale04
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm.nextYearObj.monthFill04
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "fontS14 omit",
                                                staticStyle: { width: "80px" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.nextYearObj.monthFill04
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget04
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("5月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("6月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "targetLine" }, [
                          _c("div", { staticClass: "targetBox" }, [
                            _c("p", { staticClass: "fontS14 targetBoxTop" }, [
                              _vm._v("7月"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店销售"),
                                ]),
                                _vm.nextYearObj.monthSale07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店充值"),
                                ]),
                                _vm.nextYearObj.monthFill07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fontS14 lineWhole" },
                                  [_vm._v("全店")]
                                ),
                                _vm.nextYearObj.shopTarget07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("8月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("9月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("10月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("11月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("12月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthSale12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthSale12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthSale12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.monthFill12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.monthFill12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.monthFill12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.nextYearObj.shopTarget12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.nextYearObj.shopTarget12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.nextYearObj.shopTarget12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.afterNextYearLoading,
                        expression: "afterNextYearLoading",
                      },
                    ],
                    staticClass: "targetPerformance",
                  },
                  [
                    _c("div", { staticClass: "targetTurnover padL10 padR10" }, [
                      _c(
                        "div",
                        [
                          _c("span", { staticClass: "fontS14 marR10" }, [
                            _vm._v(
                              _vm._s(_vm.afterNextYear) + "年 目标营业额(元)"
                            ),
                          ]),
                          _c("span", { staticClass: "fontS14 marR10" }, [
                            _vm._v("选择门店"),
                          ]),
                          _c(
                            "el-select",
                            {
                              staticClass: "inputWidthM marR15",
                              attrs: {
                                placeholder: "全部门店",
                                filterable: "",
                                clearable: "",
                                multiple: "",
                                "collapse-tags": "",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getChangeShop("afterNextYear")
                                },
                              },
                              model: {
                                value: _vm.afterNextYearShopArr,
                                callback: function ($$v) {
                                  _vm.afterNextYearShopArr = $$v
                                },
                                expression: "afterNextYearShopArr",
                              },
                            },
                            _vm._l(_vm.shopInfoData, function (item) {
                              return _c("el-option", {
                                key: item.shopId,
                                attrs: {
                                  label: item.shopName,
                                  value: item.shopId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "targetContent" }, [
                      _c("div", { staticClass: "targetSummary" }, [
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualTarget.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("全店年度目标"),
                                ]),
                                _vm.afterNextYearObj.totalAmount
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.totalAmount,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.afterNextYearObj
                                                    .totalAmount
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualsales.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("门店年度销售额"),
                                ]),
                                _vm.afterNextYearObj.monthSaleTotal
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSaleTotal,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.afterNextYearObj
                                                    .monthSaleTotal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "summaryItem" },
                          [
                            _c("el-image", {
                              staticStyle: { width: "40px", height: "40px" },
                              attrs: {
                                src: require("@/assets/images/annualRecharge.png"),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "itemText" },
                              [
                                _c("p", { staticClass: "fontS14" }, [
                                  _vm._v("门店年度充值额"),
                                ]),
                                _vm.afterNextYearObj.monthFillTotal
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFillTotal,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "105px" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.afterNextYearObj
                                                    .monthFillTotal
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "105px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "targetData" }, [
                        _c("div", { staticClass: "targetLine" }, [
                          _c("div", { staticClass: "targetBox" }, [
                            _c("p", { staticClass: "fontS14 targetBoxTop" }, [
                              _vm._v("1月"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店销售"),
                                ]),
                                _vm.afterNextYearObj.monthSale01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店充值"),
                                ]),
                                _vm.afterNextYearObj.monthFill01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fontS14 lineWhole" },
                                  [_vm._v("全店")]
                                ),
                                _vm.afterNextYearObj.shopTarget01
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget01,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget01
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("2月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget02
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget02,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget02
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("3月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget03
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget03,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget03
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("4月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale04
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _vm.afterNextYearObj.monthFill04
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "fontS14 omit",
                                                staticStyle: { width: "80px" },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.afterNextYearObj
                                                      .monthFill04
                                                  )
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget04
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget04,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget04
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("5月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget05
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget05,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget05
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("6月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget06
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget06,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget06
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "targetLine" }, [
                          _c("div", { staticClass: "targetBox" }, [
                            _c("p", { staticClass: "fontS14 targetBoxTop" }, [
                              _vm._v("7月"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店销售"),
                                ]),
                                _vm.afterNextYearObj.monthSale07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c("span", { staticClass: "fontS14 marR20" }, [
                                  _vm._v("门店充值"),
                                ]),
                                _vm.afterNextYearObj.monthFill07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "fontS14 lineWhole" },
                                  [_vm._v("全店")]
                                ),
                                _vm.afterNextYearObj.shopTarget07
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget07,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget07
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("8月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget08
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget08,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget08
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("9月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget09
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget09,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget09
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("10月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget10
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget10,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget10
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("11月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget11
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget11,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget11
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                          _c("div", { staticClass: "targetBox targetBoxMar" }, [
                            _c(
                              "p",
                              { staticClass: "fontS14 targetBoxTopItem" },
                              [_vm._v("12月")]
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthSale12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthSale12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthSale12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.monthFill12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.monthFill12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj.monthFill12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "targetBoxLine" },
                              [
                                _vm.afterNextYearObj.shopTarget12
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            _vm.afterNextYearObj.shopTarget12,
                                          placement: "top",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "fontS14 omit",
                                            staticStyle: { width: "80px" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.afterNextYearObj
                                                  .shopTarget12
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "span",
                                      {
                                        staticClass: "fontS14 lightGray",
                                        staticStyle: { width: "80px" },
                                      },
                                      [_vm._v("未设置")]
                                    ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }