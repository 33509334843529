var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap x-f",
      attrs: { id: "wrap" },
    },
    [
      _c("categoryTreeCard", {
        attrs: {
          categoryTreeTitle: "机器管理",
          isChildLevelIds: true,
          boxHeight: _vm.boxHeight,
          boxInternalHeight: _vm.boxInternalHeight,
          categoryTreeOptions: _vm.shopTreeOptions,
        },
        on: { allNodeIds: _vm.allNodeIds },
      }),
      _c(
        "div",
        { staticClass: "rightBox y-b" },
        [
          _c(
            "cardTitleCom",
            {
              staticClass: "headGoodsSearch",
              attrs: { cardTitle: "查询", id: "search-card" },
            },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "navSearch" },
                  [
                    _c("seniorSearch", {
                      attrs: {
                        getList: _vm.getList,
                        dropDownList: _vm.dropDownList,
                        isAdvancedSearch: false,
                        isBillDate: false,
                        isSearchInput: true,
                        isSearchInputTitle: "输入机器编码、名称",
                      },
                      model: {
                        value: _vm.queryParams,
                        callback: function ($$v) {
                          _vm.queryParams = $$v
                        },
                        expression: "queryParams",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { staticClass: "headGoodsTable", attrs: { cardTitle: "单据信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "goodsMessage" },
                  [
                    _c("operatingButton", {
                      attrs: {
                        getList: _vm.getList,
                        isRecycleBtn: true,
                        isDeleteBtn: false,
                        multiple: _vm.multiple,
                        hasPermiAddBtn: ["shop:machine:list:add"],
                        hasPermiRecycleBtn: ["shop:machine:list:remove"],
                      },
                      on: {
                        handleAdd: _vm.handleAdd,
                        handleDelete: _vm.handleDelete,
                        handleRedoDelete: function ($event) {
                          _vm.dialogOptions.show = true
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "specialDiyFinalBtn",
                          fn: function () {
                            return [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "hasPermi",
                                      rawName: "v-hasPermi",
                                      value: ["shop:machine:list:reset"],
                                      expression: "['shop:machine:list:reset']",
                                    },
                                  ],
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-connection",
                                    size: "mini",
                                  },
                                  on: { click: _vm.untieOperation },
                                },
                                [_vm._v("重置 ")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _c(
                      "div",
                      [
                        _c(
                          "el-table",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.loadingTable,
                                expression: "loadingTable",
                              },
                            ],
                            ref: "multipleTable",
                            staticStyle: { width: "100%" },
                            attrs: {
                              data: _vm.tableData,
                              "tooltip-effect": "dark",
                              border: "",
                              "max-height": _vm.tableHeight,
                              height: _vm.tableHeight,
                            },
                            on: {
                              "selection-change": _vm.handleSelectionChange,
                              "row-click": _vm.handleRowClick,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                width: "50",
                                align: "center",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "序号",
                                align: "center",
                                type: "index",
                                width: "80",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机编号",
                                align: "center",
                                prop: "machineNo",
                                "show-overflow-tooltip": "",
                                "min-width": "180",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-link",
                                        {
                                          ref: "elLink",
                                          attrs: {
                                            type: "primary",
                                            target: "_blank",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleDetail(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.machineNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "收银机名称",
                                align: "center",
                                prop: "machineName",
                                "show-overflow-tooltip": "",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopNo",
                              attrs: {
                                align: "center",
                                prop: "shopNo",
                                label: "门店编号",
                                "min-width": "180",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              key: "shopName",
                              attrs: {
                                label: "门店名称",
                                align: "center",
                                prop: "shopName",
                                "show-overflow-tooltip": "",
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "是否主收银机",
                                align: "center",
                                prop: "isMain",
                                "min-width": "100",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.isMain
                                              ? "主收银机"
                                              : "副收银机"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              key: "zjm",
                              attrs: {
                                label: "助记码",
                                align: "center",
                                prop: "zjm",
                                "show-overflow-tooltip": "",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "计算机名称",
                                align: "center",
                                prop: "deviceName",
                                "show-overflow-tooltip": "",
                                "min-width": "165",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "工作状态",
                                align: "center",
                                prop: "status",
                                "show-overflow-tooltip": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("dict-tag", {
                                        attrs: {
                                          options:
                                            _vm.dict.type.machine_working_state,
                                          value: scope.row.status,
                                        },
                                      }),
                                    ]
                                  },
                                },
                              ]),
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "应用终端类型",
                                align: "center",
                                prop: "applicationTerminalType",
                                "show-overflow-tooltip": "",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "下载终端",
                                align: "center",
                                prop: "downloadTerminal",
                                "show-overflow-tooltip": "",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "锁定人",
                                align: "center",
                                prop: "lockCashierName",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "开始使用时间",
                                align: "center",
                                prop: "startUseTime",
                                "show-overflow-tooltip": "",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "最近使用时间",
                                align: "center",
                                prop: "lastUseTime",
                                "show-overflow-tooltip": "",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "备注",
                                align: "center",
                                prop: "remark",
                                "show-overflow-tooltip": true,
                                "min-width": "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "重置人",
                                align: "center",
                                prop: "resetBy",
                                "min-width": "120",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "重置时间",
                                align: "center",
                                prop: "resetTime",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              key: "updateBy",
                              attrs: {
                                label: "修改人",
                                align: "center",
                                prop: "updateBy",
                                "show-overflow-tooltip": true,
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "修改时间",
                                align: "center",
                                prop: "updateTime",
                                "show-overflow-tooltip": "",
                                "min-width": "155",
                              },
                            }),
                            _c("el-table-column", {
                              key: "createBy",
                              attrs: {
                                label: "创建人",
                                align: "center",
                                prop: "createBy",
                                "show-overflow-tooltip": true,
                                "min-width": "130",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "创建时间",
                                align: "center",
                                prop: "createTime",
                                "show-overflow-tooltip": "",
                                "min-width": "155",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("bottomPage", {
                          attrs: {
                            getList: _vm.getList,
                            idsCom: _vm.ids,
                            totalCom: _vm.total,
                          },
                          on: { selectAllCom: _vm.selectAll },
                          model: {
                            value: _vm.queryParams,
                            callback: function ($$v) {
                              _vm.queryParams = $$v
                            },
                            expression: "queryParams",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 500, showDialog: _vm.open },
        on: {
          "update:showDialog": function ($event) {
            _vm.open = $event
          },
          "update:show-dialog": function ($event) {
            _vm.open = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.DetailLoading,
                        expression: "DetailLoading",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.machineForm,
                      rules: _vm.rules,
                      "label-width": "100px",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "所属门店", prop: "shopId" } },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options: _vm.shopTreeOptions,
                                    "show-count": true,
                                    placeholder: "请选择门店名称",
                                    normalizer: _vm.normalizer,
                                    "disable-branch-nodes": true,
                                    multiple: false,
                                  },
                                  on: { input: _vm.inputSelect },
                                  model: {
                                    value: _vm.machineForm.shopId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.machineForm, "shopId", $$v)
                                    },
                                    expression: "machineForm.shopId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "收银机编号",
                                  prop: "machineNo",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "addinput",
                                  attrs: {
                                    disabled: "",
                                    placeholder: "请输入收银机编号",
                                    maxlength: "20",
                                  },
                                  model: {
                                    value: _vm.machineForm.machineNo,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.machineForm,
                                        "machineNo",
                                        $$v
                                      )
                                    },
                                    expression: "machineForm.machineNo",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "收银机名称",
                                  prop: "machineName",
                                },
                              },
                              [
                                _c("el-input", {
                                  staticClass: "addinput",
                                  attrs: {
                                    placeholder: "请输入收银机名称",
                                    maxlength: "40",
                                  },
                                  model: {
                                    value: _vm.machineForm.machineName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.machineForm,
                                        "machineName",
                                        $$v
                                      )
                                    },
                                    expression: "machineForm.machineName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "是否主收银机",
                                  prop: "isMain",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.machineForm.isMain,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.machineForm, "isMain", $$v)
                                      },
                                      expression: "machineForm.isMain",
                                    },
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: true } }, [
                                      _vm._v("是"),
                                    ]),
                                    _c(
                                      "el-radio",
                                      { attrs: { label: false } },
                                      [_vm._v("否")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 22 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注", prop: "remark" } },
                              [
                                _c("el-input", {
                                  staticClass: "addinput",
                                  attrs: {
                                    type: "textarea",
                                    placeholder:
                                      "备注长度介于 1 和 80 字符之间",
                                    autosize: { minRows: 2, maxRows: 4 },
                                    maxlength: "80",
                                  },
                                  model: {
                                    value: _vm.machineForm.remark,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.machineForm, "remark", $$v)
                                    },
                                    expression: "machineForm.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini" },
                    on: { click: _vm.closeDia },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["shop:machine:list:add"],
                        expression: "['shop:machine:list:add']",
                      },
                    ],
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.saveAndAdd },
                  },
                  [_vm._v("保存并新增")]
                ),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "hasPermi",
                        rawName: "v-hasPermi",
                        value: ["shop:machine:list:add"],
                        expression: "['shop:machine:list:add']",
                      },
                    ],
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.submitForm },
                  },
                  [_vm._v("保存")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("c-dialog", {
        attrs: {
          title: "请输入登录密码",
          width: 450,
          showDialog: _vm.resetUnbindOpen,
        },
        on: {
          "update:showDialog": function ($event) {
            _vm.resetUnbindOpen = $event
          },
          "update:show-dialog": function ($event) {
            _vm.resetUnbindOpen = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    ref: "permissionForm",
                    attrs: {
                      model: _vm.resetUnbindForm,
                      "label-width": "90px",
                    },
                    nativeOn: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "密码", prop: "sourceCashierId" } },
                      [
                        _c("el-input", {
                          ref: "inputRef",
                          staticStyle: { width: "250px" },
                          attrs: {
                            "show-password": "",
                            placeholder: "请输入登录密码",
                            size: "mini",
                          },
                          nativeOn: {
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              return _vm.submitResetUnbindForm($event)
                            },
                          },
                          model: {
                            value: _vm.resetUnbindForm.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.resetUnbindForm, "password", $$v)
                            },
                            expression: "resetUnbindForm.password",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "x-f" }, [
                  _c("span", [_vm._v("*")]),
                  _c(
                    "span",
                    {
                      staticClass: "fontS14",
                      staticStyle: { color: "#ff4949" },
                    },
                    [_vm._v("重置收银机，可能会导致数据丢失，请谨慎操作")]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini" },
                    on: {
                      click: function ($event) {
                        _vm.resetUnbindOpen = false
                      },
                    },
                  },
                  [_vm._v("取 消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "marL10",
                    attrs: { size: "mini", type: "primary" },
                    on: { click: _vm.submitResetUnbindForm },
                  },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.handleEvent,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }