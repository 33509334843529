var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "wrap",
    },
    [
      _c("topOperatingButton", {
        attrs: { isSubmitAddBtn: false, isAuditBillBtn: false },
        on: { submitForm: _vm.submitForm, getQuit: _vm.getQuit },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.licenseForm,
            rules: _vm.rules,
            "label-width": "110px",
          },
        },
        [
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "基本信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c("div", { staticClass: "tableContent" }, [
                  _c(
                    "div",
                    { staticClass: "x-w" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分店", prop: "shopId" } },
                        [
                          _c("SelectRemote", {
                            staticClass: "marR20",
                            staticStyle: { width: "250px" },
                            attrs: {
                              option: _vm.$select({
                                key: "listShop",
                                option: {
                                  option: {
                                    showItem: [
                                      {
                                        shopId: _vm.licenseForm.shopId,
                                        shopName: _vm.licenseForm.shopName,
                                      },
                                    ],
                                  },
                                },
                              }).option,
                            },
                            model: {
                              value: _vm.licenseForm.shopId,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseForm, "shopId", $$v)
                              },
                              expression: "licenseForm.shopId",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店经度" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: "",
                              size: "mini",
                              placeholder: "门店经度",
                            },
                            model: {
                              value: _vm.licenseForm.longitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseForm, "longitude", $$v)
                              },
                              expression: "licenseForm.longitude",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店纬度" } },
                        [
                          _c("el-input", {
                            staticClass: "inputWidth",
                            attrs: {
                              disabled: "",
                              size: "mini",
                              placeholder: "门店纬度",
                            },
                            model: {
                              value: _vm.licenseForm.latitude,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseForm, "latitude", $$v)
                              },
                              expression: "licenseForm.latitude",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "门店地址" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              disabled: "",
                              size: "mini",
                              placeholder: "门店地址",
                            },
                            model: {
                              value: _vm.licenseForm.totalAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseForm, "totalAddress", $$v)
                              },
                              expression: "licenseForm.totalAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              type: "textarea",
                              placeholder: "备注长度介于 1 和 80 字符之间",
                              maxlength: "80",
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.licenseForm.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseForm, "remark", $$v)
                              },
                              expression: "licenseForm.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            2
          ),
          _c(
            "cardTitleCom",
            { attrs: { cardTitle: "商家信息" } },
            [
              _c("template", { slot: "cardContent" }, [
                _c(
                  "div",
                  { staticClass: "tableContentImg y-start" },
                  [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "上传营业执照",
                          "label-width": "155px",
                        },
                      },
                      [
                        _c("accessory-upload", {
                          staticClass: "marB20",
                          attrs: {
                            listType: "picture-card",
                            limit: 1,
                            title: "",
                            fileList: _vm.fileListLicenseImageUrl,
                          },
                          on: {
                            getFileItems: function ($event) {
                              return _vm.getFileItemsDataLogo($event, 1)
                            },
                            delFileItems: function ($event) {
                              return _vm.delFileItemsLogo(1)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "上传食品经营许可证",
                          "label-width": "155px",
                        },
                      },
                      [
                        _c("accessory-upload", {
                          staticClass: "marB20",
                          attrs: {
                            listType: "picture-card",
                            limit: 1,
                            title: "",
                            fileList: _vm.fileListQualificationExpiration,
                          },
                          on: {
                            getFileItems: function ($event) {
                              return _vm.getFileItemsDataLogo($event, 2)
                            },
                            delFileItems: function ($event) {
                              return _vm.delFileItemsLogo(2)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x marB20" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "上传身份证",
                              "label-width": "155px",
                            },
                          },
                          [
                            _c("accessory-upload", {
                              attrs: {
                                listType: "picture-card",
                                limit: 1,
                                isLimitMany: true,
                                title: "",
                                fileList: _vm.fileListIdCardFrontImageUrl,
                              },
                              on: {
                                getFileItems: function ($event) {
                                  return _vm.getFileItemsDataLogo($event, 3)
                                },
                                delFileItems: function ($event) {
                                  return _vm.delFileItemsLogo(3)
                                },
                              },
                            }),
                            _c("div", [
                              _c("span", [_vm._v("点击上传")]),
                              _c("span", { staticClass: "fontS14B" }, [
                                _vm._v("人像面"),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "50px" } },
                          [
                            _c("accessory-upload", {
                              attrs: {
                                listType: "picture-card",
                                limit: 1,
                                isLimitMany: true,
                                title: "",
                                fileList: _vm.fileListIdCardBackImageUrl,
                              },
                              on: {
                                getFileItems: function ($event) {
                                  return _vm.getFileItemsDataLogo($event, 4)
                                },
                                delFileItems: function ($event) {
                                  return _vm.delFileItemsLogo(4)
                                },
                              },
                            }),
                            _c("div", [
                              _c("span", [_vm._v("点击上传")]),
                              _c("span", { staticClass: "fontS14B" }, [
                                _vm._v("国徽面"),
                              ]),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "企业名称",
                          prop: "companyName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入企业名称",
                          },
                          model: {
                            value: _vm.licenseForm.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "companyName", $$v)
                            },
                            expression: "licenseForm.companyName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "工商营业执照号",
                          prop: "licenseId",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入工商营业执照号",
                          },
                          model: {
                            value: _vm.licenseForm.licenseId,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "licenseId", $$v)
                            },
                            expression: "licenseForm.licenseId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "工商营业执照有效期",
                          prop: "licenseExpiration",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "请选择工商营业执照有效期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.licenseForm.licenseExpiration,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "licenseExpiration",
                                $$v
                              )
                            },
                            expression: "licenseForm.licenseExpiration",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "法人姓名",
                          prop: "legalPersonName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入法人姓名",
                          },
                          model: {
                            value: _vm.licenseForm.legalPersonName,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "legalPersonName", $$v)
                            },
                            expression: "licenseForm.legalPersonName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "法人身份证号 ",
                          prop: "idCardNo",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入法人身份证号 ",
                          },
                          model: {
                            value: _vm.licenseForm.idCardNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "idCardNo", $$v)
                            },
                            expression: "licenseForm.idCardNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "法人身份证有效期",
                          prop: "idCardExpiration",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "请选择法人身份证有效期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.licenseForm.idCardExpiration,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "idCardExpiration", $$v)
                            },
                            expression: "licenseForm.idCardExpiration",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "食品经营许可证有效期",
                          prop: "qualificationExpiration",
                        },
                      },
                      [
                        _c("el-date-picker", {
                          attrs: {
                            size: "mini",
                            type: "date",
                            placeholder: "请选择食品经营许可证有效期",
                            "value-format": "yyyy-MM-dd",
                          },
                          model: {
                            value: _vm.licenseForm.qualificationExpiration,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.licenseForm,
                                "qualificationExpiration",
                                $$v
                              )
                            },
                            expression: "licenseForm.qualificationExpiration",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "联系人",
                          prop: "contactName",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: { size: "mini", placeholder: "请输入联系人" },
                          model: {
                            value: _vm.licenseForm.contactName,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "contactName", $$v)
                            },
                            expression: "licenseForm.contactName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "联系人电话",
                          prop: "contactPhone",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "inputWidth",
                          attrs: {
                            size: "mini",
                            placeholder: "请输入联系人电话",
                          },
                          model: {
                            value: _vm.licenseForm.contactPhone,
                            callback: function ($$v) {
                              _vm.$set(_vm.licenseForm, "contactPhone", $$v)
                            },
                            expression: "licenseForm.contactPhone",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          "label-width": "170px",
                          label: "主体类型",
                          prop: "licenseType",
                        },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.licenseForm.licenseType,
                              callback: function ($$v) {
                                _vm.$set(_vm.licenseForm, "licenseType", $$v)
                              },
                              expression: "licenseForm.licenseType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 91 } }, [
                              _vm._v("企业"),
                            ]),
                            _c("el-radio", { attrs: { label: 92 } }, [
                              _vm._v("个体工商户"),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }