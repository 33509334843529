<template>
  <!--门店盘点单 -->
  <div>
    <checkDetail :orderType="orderType" />
  </div>
</template>

<script>
import checkDetail from '@/views/components/shop/checkDetail.vue'

export default {
  name: 'checkDetailS',
  components: { checkDetail },
  data () {
    return {
      orderType: 140309
    }
  }
}
</script>
